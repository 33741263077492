<template>
    <LiefengContent>
        <template v-slot:title>访问纪录</template>
        <template v-slot:toolsbarLeft>
            <Select v-model="actionType" style="width: 120px; margin-right: 5px" placeholder="请选择类型">
                <Option :value="item.dictKey" v-for="(item, index) in selectList" :key="index">{{ item.dictValue }}</Option>
            </Select>
            <Button
                type="info"
                @click="
                    () => {
                        getList()
                    }
                "
            >
                查询
            </Button>
            <Button
                type="default"
                @click="
                    () => {
                        restData()
                    }
                "
            >
                清空条件
            </Button>
        </template>
        <template v-slot:contentArea>
            <div class="leftMenu">
                <LiefengTable
                    :talbeColumns="tableColumns"
                    :tableData="tableData"
                    :loading="loading"
                    :fixTable="true"
                    :curPage="page"
                    :total="total"
                    :pagesizeOpts="[20, 30, 50, 100]"
                    :page-size="pageSize"
                    @hadlePageSize="hadlePageSize"
                    @tableSelect="onSelect"
                ></LiefengTable>
            </div>
        </template>
    </LiefengContent>
</template>

<script>
//@route('/test15minmanage')
import LiefengContent from "@/components/LiefengContent3"
import LiefengTable from "@/components/LiefengTable"
import LiefengModal from "@/components/LiefengModal"
import LiefengUploadExcel from "@/components/LiefengUploadExcel"
import { BaiduMap, BmMarker, BmView, BmNavigation } from "vue-baidu-map"
import LiefengUpload from "@/components/LiefengUpload"
import LiefengUploadAudio from "@/components/LiefengUploadAudio"
export default {
    components: {
        LiefengContent,
        LiefengTable,
        LiefengModal,
        LiefengUploadExcel,
        BaiduMap,
        BmMarker,
        BmView,
        BmNavigation,
        LiefengUpload,
        LiefengUploadAudio,
    },
    data() {
        return {
            selectList: [
                {
                    dictValue: "浏览",
                    dictKey: "0",
                },
                {
                    dictValue: "拨打电话",
                    dictKey: "1",
                },
                {
                    dictValue: "定位",
                    dictKey: "2",
                },
                {
                    dictValue: "点赞",
                    dictKey: "3",
                },
            ],
            tableData: [],
            page: 1,
            pageSize: 20,
            total: 0,
            loading: false,
            tableColumns: [
                {
                    title: "姓名",
                    minWidth: 100,
                    key: "realName",
                    align: "center",
                },
                {
                    title: "性别",
                    minWidth: 100,
                    key: "sex",
                    align: "center",
                    render: (h, params) => {
                        return h("div", {}, params.row.sex == 0 ? "未知" : params.row.sex == 1 ? "男" : params.row.sex == 2 ? "女" : "未知")
                    },
                },
                // {
                //     title: "身份类型",
                //     minWidth: 100,
                //     key: "idNumType",
                //     align: "center",
                //     render: (h, params) => {
                //         return h(
                //             "div",
                //             {},
                //             params.row.idNumType == 1 ? "内地身份证" : params.row.idNumType == 2 ? "港澳台身份证" : params.row.idNumType == 3 ? "护照" : params.row.idNumType == 4 ? "其他" : "无"
                //         )
                //     },
                // },
                {
                    title: "社区",
                    minWidth: 100,
                    key: "communityName",
                    align: "center",
                },
                {
                    title: "街道",
                    minWidth: 100,
                    key: "streetName",
                    align: "center",
                },
                {
                    title: "手机号码",
                    minWidth: 100,
                    key: "account",
                    align: "center",
                },
                {
                    title: "操作次数",
                    minWidth: 100,
                    key: "actionNum",
                    align: "center",
                },
                {
                    title: "操作类型",
                    minWidth: 100,
                    key: "actionType",
                    align: "center",
                },
            ],
            actionType: "",
        }
    },
    methods: {
        restData() {
            this.actionType = ""
            this.page = 1
            this.getList()
        },
        hadlePageSize(obj) {
            this.pageSize = obj.pageSize
            this.page = obj.page
            this.getList()
        },
        getList() {
            this.loading = true
            this.$get("/gateway/api/syscenic/pc/point/listPointActionByPage", {
                scenicId: this.$route.query.scenicId,
                page: this.page,
                pageSize: this.pageSize,
                pointId: this.$route.query.pointId,
                actionType: this.actionType,
            }).then(res => {
                if (res.code == 200 && res.dataList) {
                    this.tableData = res.dataList.map(item => {
                        return {
                            ...item,
                            actionType: (() => {
                                switch (item.actionType) {
                                    case "0":
                                        return "浏览"
                                    case "1":
                                        return "拨打电话"
                                    case "2":
                                        return "定位"
                                    case "3" :
                                        return "点赞"
                                    default:
                                        return ""
                                }
                            })(),
                        }
                    })
                    this.page = res.currentPage
                    this.pageSize = res.pageSize
                    this.total = res.maxCount
                }
                this.$Message.destroy()
                this.loading = false
            })
        },
    },
    async created() {
        this.getList()
    },
}
</script>

<style lang='less'>
.demo-tree-render .ivu-tree-title {
    width: calc(100% - 34px);
    padding: 10px;
    & > span {
        & > span:first-of-type {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            width: 70%;
            display: inline-block;
        }
    }
}
.demo-tree-render .ivu-tree-arrow {
    padding: 10px;
}
.ivu-breadcrumb {
    background-color: #fff;
    line-height: 36px;
    padding: 0 20px;
    margin-bottom: 10px;
    position: relative;
}
.form {
    padding: 10px 30px 50px 10px;
    height: 100%;
    overflow: scroll;
}
.noContent {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f9f9f9;
    color: #999;
    font-size: 36px;
}
.validate {
    &::before {
        content: "*";
        display: inline-block;
        margin-right: 4px;
        line-height: 1;
        font-family: SimSun;
        font-size: 14px;
        color: #ed4014;
    }
}
.toolsbarRight {
    display: flex;
    padding-top: 10px;
}
</style>
